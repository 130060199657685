body{
    min-height: 100vh;
    background-color: #efefef;
    background: url("../public/bg.jpeg");
    background-repeat: space;
    background-position: bottom;
    background-size: initial;
    background-blend-mode: soft-light;
}
body::before{
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6); /* Fondo blanco semitransparente (RGBA) */
    z-index: -1;
}
.main-container{
    background-color: transparent;
    padding: 1rem;
}

.main-content{
    boder:solid 1px #efeeee;
    box-shadow: rgb(0 0 0 / 12%) 0px 1px 6px, rgb(0 0 0 / 12%) 0px 1px 4px;
    background-color: #FFFFFF;
    padding: 1rem;
}

.card{
    box-shadow: rgb(0 0 0 / 12%) 0px 1px 6px, rgb(0 0 0 / 12%) 0px 1px 4px;
    border-radius: 0;
}

.menu-container{
    box-shadow: rgb(0 0 0 / 12%) 0px 1px 6px, rgb(0 0 0 / 12%) 0px 1px 4px;
}

.new-button-fixed{
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    border-radius: 50%;
    box-shadow: #828387 3px 3px 5px 1px;
}

.new-button-absolute{
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    border-radius: 50%;
    box-shadow: #828387 3px 3px 5px 1px;
}

.reload-button-fixed{
    position: fixed;
    bottom: 6rem;
    right: 2rem;
    border-radius: 50%;
    box-shadow: #828387 3px 3px 5px 1px;
}

.reload-button-absolute{
    position: absolute;
    bottom: 4.5rem;
    right: 0.5rem;
    border-radius: 50%;
    box-shadow: #828387 3px 3px 5px 1px;
}

.toast-custom{
    position: fixed;
    top:5rem;
    right: 3rem;
}

.popover-size-lg{
    max-width: 400px !important;
}

@media (min-width: 1200px) {
    .modal-xl {
        --bs-modal-width: 95%;
    }
}